<template>
  <!-- 地址管理 -->
  <div>
    <div class="bg_gray"></div>
    <div class="banklist">
      <headbox
        title="银行卡管理"
        bgColor="#fff"
        isborder="true"
        :isbackperson="true"
        path="person"
      ></headbox>
      <template v-if="!isHaveData">
       <van-empty
                       class="custom-image"
                       :image="require('@/assets/images/pic_no information@2x.png')"
                       description="暂无数据!"
                     />
      </template>
      <div class="padd" v-if="isHaveData">
        <div >
          
         <div class="whitebox" v-for="items in bankList" :key="items.id">
        <div class="addressbox">
          <div class="flex infobox">
            <div>{{ items.name }}</div>
            <div class="marginLeft">{{ items.bank }}</div>
          </div>
          <div class="adressdetails">
            {{ items.num }}
          </div>
        </div>
        <!--  -->
        <div class="flex-between bottombox">
          <div></div>
          <div v-if="items.def" class="abscheckbox">
            <el-checkbox
              v-model="items.def"
            ></el-checkbox>
            <span>默认银行卡</span>
          </div>
          <div v-else class="abscheckbox">
            <div
              @click="
                initdata(
                  items.cid,
                  items.name,
                  items.num,
                  items.bank,
                )
              "
            >
              <el-checkbox></el-checkbox> <span> 设置为默认银行卡</span>
            </div>
          </div>
          <div class="flex bottomboxbtn">
             <div
              class="edit"
              @click="
                editBank(
                  items.cid,
                  items.name,
                  items.num,
                  items.bank,
                )
              "
            >
              <i class="el-icon-edit-outline"></i>编辑
            </div>
<!--            <div @click="deleteAddress(items.cid)">
              <i class="el-icon-delete-solid"></i>删除
            </div>-->
          </div>
        </div>

        
         </div>
        </div>
      </div>

<!--      <div class="btnBox" @click="addressEdit">新增银行卡</div>-->
      <van-dialog
        v-model="deleteBox"
        show-cancel-button
        @confirm="postModel"
        @cancel="closeModel"
      >
        <div class="centerbox">确定要删除该地址吗？</div>
      </van-dialog>
    </div>
<!--    <div class="footer">
      <div class="detailsbox" style="padding-bottom: 10px">如需修改银行卡账号，请拨打客服电话</div>
      <a class="detailsbox" style="color: #ff2d2e" :href="'tel:' + '400-860-6806'">400-860-6806</a>
    </div>-->
  </div>
</template>
<script>
import headbox from "@/components/head.vue";
import * as api from "@/utils/api";

export default {
  inject: ["reload"],
  data() {
    return {
      bankList: [],
      // 删除地址的ID
      deleteId: 0,
      deleteBox: false,
      // 上一个页面是否是上门取货
      parentpage: "",
      backperson: false,
    //   判断是否有数据
      isHaveData:true,
    };
  },
  mounted() {
    api.getBankList().then(res => {
      this.bankList = res;
      if (this.bankList.length == 0) {
        this.isHaveData = false
      }
    });
  },
  methods: {
    addressEdit(){
      this.$router.push({
        name:'AddBank',
        query:{type:'addSave'}
      })
    },
    // 设置为默认地址
    initdata(id,name,num,bank) {
        // id 持卡人  银行卡号 银行名称
      const bankData = {
            num,
            name,
            bank,
            def:true

      };
      // 修改地址
      api.EditBank(bankData,id).then(res => {
            api.getBankList().then(res => {
      this.bankList = res;
      if (this.bankList.length == 0) {
        this.isHaveData = false
      }
    });
      });
    },
    // 默认地址选择
    // checkboxaddress() {},
    // 确认删除弹窗按钮
    postModel() {
      api.delBank(this.deleteId).then(res => {
        this.reload();
      });
    },
    closeModel() {
    },
    // 编辑地址
    // items.name,items.phone ,items.address ,items.preAddr
    editBank(id,name,num,bank) {
        num = JSON.stringify(num)
      this.$router.push({
        name: "AddBank",
        query: {
          type: "EditSave",
          id,
          name,
          num,
          bank
        }
      });
    },
    // 删除地址
    deleteAddress(thanId) {
      this.deleteId = thanId;
      this.deleteBox = true;
    },
    selldetils() {
      this.$router.push({ name: "selldetails" });
    }
  },
  components: {
    headbox
  }
};
</script>

<style lang="scss" scoped="true">
::v-deep{
    .el-checkbox__inner{
        border-radius: 50%;
        border-color:1px solid #e9e9e9;
    }
    .el-checkbox__inner:hover {
        border-color: #e9e9e9!important;
    }
    .is-checked{
        .el-checkbox__inner{
                background-color: #ff2d2e !important;
        }
    }
    .van-empty__image{
        width: 420px;
        height: 260px;
    }
}
::v-deep{
.van-dialog__confirm{
  color: #ff2d2e !important;
}
.van-dialog__cancel {
  line-height: 80px;
  position: relative;
  top: 20px;
  border: none !important;
}
.van-dialog__message {
  font-size: 32px;
}
.van-dialog {
  width: 550px;
}
}
.centerbox {
  font-size: 32px;
  text-align: center;
  padding: 60px 0;
  // width: 420px!important;
}
.bottomboxbtn {
  font-size: 24px;
  i {
    font-size: 30px;
    margin-right: 5px;
  }
  .edit {
    margin-right: 30px;
  }
}
.adressdetails {
  height: 50px;
  font-size: 24px;
  color:#666666;
  // margin-bottom: 15px;
}
.infobox {
  font-size: 28px;
  margin-bottom: 10px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #242424;
}
.flex{
  display: flex;
}
.bottombox {
  position: relative;
  color: #999999;
  border-top: 1px solid #E9E9E9;
  padding: 32px 30px;
  .abscheckbox {
    position: absolute;
    // left: 20px;
    // top: -5px;
  }
  span {
    position: relative;
    top: 0px;
    font-size: 20px;
    margin-left: 10px;
    display: inline-block;
    vertical-align: baseline;
  }
}

.addressbox {
  padding: 30px 30px 0;
}
.whitebox {
  margin-bottom: 20px;
  background: #fff;
  border-radius: 8px 8px 8px 8px;
}
.padd{
  padding: 20px 30px;

}
.h2 {
  margin: 15px 0 30px;
  color: #333333;
  font-size: 36px;
}
.details {
  color: #999999;
  font-size: 28px;
}
.orcodebox {
  margin: 50px auto;
  width: 400px;
  height: 360px;
}
.flex2 {
  flex: 2;
}
.titlebox {
  font-size: 36px;
  margin: 20px 0 10px;
}
.detailsbox {
  font-size: 28px;
  color: #999999;
}
.phonekefu {
  width: 50px;
  height: 50px;
}
.bg_gray {
  background: #f5f5f5;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
}
.banklist {
  position: relative;
  z-index: 1;
}
.kefubox {
  align-items: center;
  padding: 30px;
  border-radius: 12px;
  margin: 30px;
  background: #fff;
  box-shadow: 0 0 9px #ccc;
}
.headbox {
  width: 120px;
  height: 120px;
  margin-right: 30px;
}
.btnBox{
  width: 90%;
  height: 80px;
  border-radius: 80px;
  color: #fff;
  font-size: 32px;
  margin: 20px auto;
  background-color: red;
  line-height: 80px;
  text-align: center;
}
.marginLeft{
  margin-left: 30px;
}
.custom-image{
    height: 300px;
    width: 100%;
}
.footer{
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0px 20px 40px;
  z-index: 2;
  text-align: center;
  //background-color: #FFFFFF;
}
</style>
